import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Map } from './components'
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import Button from '@material-ui/core/Button';
import IconButton from "@material-ui/core/IconButton";
// @material-ui/icons
import Menu from "@material-ui/icons/Menu";
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
// import StarIcon from '@material-ui/icons/StarBorder'; // icon example
import { withStyles } from '@material-ui/core/styles';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import 'typeface-pathway-gothic-one';
import verifiedIcon from './components/Map/vf-verified-icon.svg';
import unverifiedIcon from './components/Map/vf-unverified-icon.svg';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#008BC1'
    },
    secondary: {
      main: '#F1C900'
    },
    textPrimary: {
      main: '#171C22',
    },
    textSecondary: {
      main: '#fff',
    },
  },
  overrides: {
    AppBar: { // Name of the component ⚛️ / style sheet
      root: { // Name of the rule
        background: 'url(https://firebasestorage.googleapis.com/v0/b/vote-fleury2018.appspot.com/o/website%2Ftrans-bg.png?alt=media&token=6c0abc57-da30-417b-aa32-3a0c7a56aa4c) no-repeat center center fixed',
        backgroundSize: 'cover',
      },
    },
  },
});

const classes = theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  appResponsive: {
    margin: "20px 10px"
  },
  appBar: {
    root: {
      background: 'url(https://firebasestorage.googleapis.com/v0/b/vote-fleury2018.appspot.com/o/website%2Ftrans-bg.png?alt=media&token=6c0abc57-da30-417b-aa32-3a0c7a56aa4c) no-repeat center center fixed',
      backgroundSize: 'cover',
    },
    position: 'fixed',
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    flexGrow: 1,
    backgroundColor: '#fff',
  },
  toolBar: {
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  desktopNavContainer: {
    width: '100%'
  },
  drawerPaper: {
    border: "none",
    bottom: "0",
    transitionProperty: "top, bottom, width",
    transitionDuration: ".2s, .2s, .35s",
    transitionTimingFunction: "linear, linear, ease",
    width: 240,
    boxShadow: "0 10px 30px -12px rgba(0, 0, 0, 0.42), 0 4px 25px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
    position: "fixed",
    display: "block",
    top: "0",
    height: "100vh",
    right: "0",
    left: "auto",
    visibility: "visible",
    overflowY: "visible",
    borderTop: "none",
    textAlign: "left",
    paddingRight: "0px",
    paddingLeft: "0",
    transition: "all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)",
  },
  navLink: {
    color: "inherit",
    position: "relative",
    padding: "0.9375rem",
    fontWeight: "400",
    fontSize: "12px",
    textTransform: "uppercase",
    borderRadius: "3px",
    lineHeight: "20px",
    textDecoration: "none",
    margin: "0px",
    display: "inline-flex",
    "&:hover,&:focus": {
      color: "inherit",
      background: "rgba(200, 200, 200, 0.2)"
    },
    [theme.breakpoints.down("sm")]: {
      width: "calc(100% - 30px)",
      marginLeft: "15px",
      textAlign: "left",
      "& > span:first-child": {
        justifyContent: "flex-start"
      }
    }
  },
  iconButton: {
    float: 'right',
  },
  logoLink: {
    display: 'block',
    width: '170px',
    position: 'relative',
    marginLeft: '14px',
    marginTop: '6px',
    [theme.breakpoints.down('sm')]: {
      width: '50%',
      marginRight: '27%',
    },
  },
  logo: {
    width: '100%',
    height: '100%',
  },
  logoIcon: {
    height: '28px',
  },
  voteLogo: {
    marginLeft: '69%',
    width: '170px',
    [theme.breakpoints.down('sm')]: {
      marginLeft: '-23%',
      width: '100px',
    },
    [theme.breakpoints.down('md')]: {
      marginLeft: '-6%',
      width: '100px',
    },
  },
  toolbarLink: {
    margin: '2.8vh auto',
    marginTop: '33px',
    marginBottom: '36px',
    marginLeft: '19px',
    marginRight: '19px',
    fontFamily: 'Pathway Gothic One',
    fontWeight: '700',
    fontSize: '1em',
    letterSpacing: '1.3px',
    '&:hover, &:focus': {
      background: 'transparent',
    },
  },
  toolbarLinkFirst: {
    marginRight: '19px',
    marginLeft: '32px',
  },
  toolbarLinkLast: {
    marginLeft: '5em',
    '&:after': {
      content: '""',
      height: '5px',
      position: 'absolute',
      display: 'block',
      bottom: '-36px',
      width: '100%',
      background: '#DD462F',
    },
  },
  toolbarCTA: {
    padding: '.85em 45px',
    borderRadius: '30px'
  },
  toolbarTitle: {
    flex: 1,
  },
  layout: {
    width: '100%',
    marginLeft: 0,
    marginRight: 0,
    [theme.breakpoints.down(900 + theme.spacing.unit * 3 * 2)]: {
      width: '100%',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    fontFamily: 'Pathway Gothic One',
    background: '#55908A',
  },
  heroContent: {
    margin: '0 auto',
    marginTop: '100px',
    padding: `${theme.spacing.unit * 8}px 0 ${theme.spacing.unit * 6}px`,
    background: 'url("/ElectionWebBannerEn.jpg")',
    fontFamily: 'Pathway Gothic One',
    [theme.breakpoints.down('sm')]: {
      marginTop: '56px',
    },
  },
  heroTitle: {
    maxWidth: '960px',
    margin: '0 auto',
    textTransform: 'uppercase',
    fontFamily: 'Pathway Gothic One',
    fontWeight: '700',
    letterSpacing: '1px',
    color: '#fff',
  },
  heroText: {
    maxWidth: '405px',
    margin: '0 auto',
    fontFamily: 'Pathway Gothic One',
    color: '#fff',
  },
  mapSubtext: {
    maxWidth: 600,
    margin: '0 auto',
    padding: `${theme.spacing.unit * 8}px 30px ${theme.spacing.unit * 6}px`,
    fontSize: '1.3em',
    color: '#fff',
  },
  keyList: {
    marginLeft: '5em',
    [theme.breakpoints.down('sm')]: {
      marginLeft: '0'
    },
  },
  keyListItem: {
    paddingTop: '11px',
    paddingBottom: '3px',
  },
  cardHeader: {
    backgroundColor: theme.palette.grey[200],
  },
  footer: {
    marginTop: 0,
    borderTop: `1px solid ${theme.palette.divider}`,
    padding: `${theme.spacing.unit * 6}px`,
    fontFamily: 'Pathway Gothic One',
    background: '#F1F1F1',
  },
  footerHeading: {
    fontFamily: 'Pathway Gothic One',
    fontWeight: '700',
    letterSpacing: '1px',
    textTransform: 'uppercase',
  },
  footerGrid: {
    borderBottom: '1px #AAA solid',
  },
  footerLink: {
    margin: 0,
    fontFamily: 'Helvetica Neue',
    fontWeight: '400',
    textTransform: 'none',
    color: '#171C22',
    textDecoration: 'inherit',
    '&:hover, &:focus, &:active': {
      textDecoration: 'underline',
      background: 'inherit',
    },
    paddingLeft: 0,
    justifyContent: 'left',
  },
  footerCopyright: {
    paddingTop: '50px',
    fontWeight: '400',
    textAlign: 'center',
  },
});

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileOpen: false
    };
    this.handleDrawerToggle = this.handleDrawerToggle.bind(this);
  }
  handleDrawerToggle() {
    this.setState({ mobileOpen: !this.state.mobileOpen });
  }

  render() {

    const { classes } = this.props;

    return (
      <MuiThemeProvider theme={theme}>
        <React.Fragment>
        <CssBaseline />
        <AppBar position="fixed" color="default" className={classes.appBar}>
          <Toolbar className={classes.toolBar}>
            <a href="#" className={classes.logoLink}>
              <Hidden mdDown implementation="css">
                <Typography variant="h6" color="inherit" noWrap className={classes.toolbarTitle}>
                  <img src="http://playmc2.com/images/logo-mc2-full-400.png" alt="MC2" className={classes.logo} />
                </Typography>
              </Hidden>
              <Hidden lgUp implementation="css">
                <Typography variant="h6" color="inherit" noWrap className={classes.toolbarTitle}>
                  <img src="http://playmc2.com/images/logo-mc2-full-400.png" alt="MC2" className={classes.logoIcon} />
                </Typography>
              </Hidden>
            </a>
            <img src="https://firebasestorage.googleapis.com/v0/b/vote-fleury2018.appspot.com/o/website%2Fmap-impact-header-logo.png?alt=media&token=8046a612-5709-4615-822a-0bcc452b1099" alt="MC2" className={classes.voteLogo} />
          </Toolbar>
        </AppBar>
        <main className={classes.layout}>
          {/* Hero unit (hiding for now)*/}
          <div className={classes.heroContent} style={{display: "none"}}>
          <Typography className={classes.heroTitle} component="h3" variant="h3" align="center" color="textSecondary" gutterBottom>
            Ottawa Vote Fleury
          </Typography>
          </div>
          {/* End hero unit */}
          <Map />
          {/* Map Sub-text; TODO: Better class-name? Style tweaks? */}
          <div className={classes.mapSubtext}>
            <Typography component="p" variant="body" align="center">
            All municipal candidates are invited to submit their campaign hashtag by Tweeting us at <a href="https://twitter.com/@MC2Universe" target="_blank" rel="noopener noreferrer">@MC2Universe</a>!
            </Typography>
            <List component="nav" className={classes.keyList}>
              <ListItem className={classes.keyListItem}>
                <ListItemIcon>
                  <img alt="v" src={verifiedIcon} height="25px" />
                </ListItemIcon>
                <ListItemText primary="Verified Action" />
              </ListItem>
              <ListItem className={classes.keyListItem}>
                <ListItemIcon>
                  <img alt="u" src={unverifiedIcon} height="25px" />
                </ListItemIcon>
                <ListItemText primary="Unverified Action" />
              </ListItem>
            </List>
          </div>
          {/* End Map Sub-text */}
        </main>
        {/* Footer */}
        <footer className={classNames(classes.footer, classes.layout)}>
          <Typography className={classes.footerCopyright} variant="subtitle2" color="textPrimary" gutterBottom>
            © 2018 MC2, Inc. 
          </Typography>
        </footer>
        {/* End footer */}
      </React.Fragment>
      </MuiThemeProvider>
    );
  }
}

App.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(classes)(App);