import React from 'react';
import Typography from '@material-ui/core/Typography';
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
  InfoWindow,
} from "react-google-maps";

import './Map.css';

import verifiedIcon from './vf-verified-icon.svg';
import unverifiedIcon from './vf-unverified-icon.svg';

class MarkerWithState extends React.Component {

  constructor(props) {
    super(props);
    this.state = { isSelected: props.isSelected };
  }

  render() {
    const twitter = this.props.action.twitterMediaURL ? true : false;
    return (
      <Marker
        position = {  this.props.position }
        icon = { this.props.icon }
        onClick = { () => this.setState({isSelected: true }) }   
      >

        { this.state.isSelected && <InfoWindow
          onCloseClick = { () =>  this.setState({isSelected: false }) }
          >
          <div style={{ backgroundColor: `white`, opacity: 0.75, padding: `12px`, width: '300px'}}>
            <div style={{ maxHeight: '250px', fontSize: `16px`, fontColor: `#08233B` }}>
                <Typography variant="h6" color='textPrimary' gutterBottom>{this.props.action.username}</Typography>
                  {twitter ? (
                    <Typography variant="body1" color='textSecondary' gutterBottom>
                      {this.props.action.userDesc.replace(/https:\/\/t.co\/[a-zA-Z0-9]*/g, "")}
                      <a href={this.props.action.twitterMediaURL} target="_blank" rel="noopener noreferrer">{this.props.action.twitterMediaURL}</a>
                    </Typography>
                  ) : (
                    <Typography variant="body1" color='textSecondary' gutterBottom>
                      {this.props.action.userDesc}
                    </Typography>
                  )}
                <img alt="GDD Media" src={this.props.action.mediaUrl} width="285px" />
            </div>
          </div>
        </InfoWindow>}
      </Marker>
    )
  }
}

function randomCloseTo(center, radius) {
  var x0 = center.lng;
  var y0 = center.lat;
  
  var rd = radius/111300;

  var u = Math.random();
  var v = Math.random();

  var w = rd * Math.sqrt(u);
  var t = 2 * Math.PI * v;
  var x = w * Math.cos(t);
  var y = w * Math.sin(t);

  var xp = x/Math.cos(y0);

  return {'lat': y+y0, 'lng': xp+x0};
}

var ottawa = {lat:45.41117, lng:-75.69812};

function unplacedParkInOttawa(action) {
  if (action.location.lat === "" || action.location.long === "") { 
    var loc = randomCloseTo(ottawa, 30 * 7000);
    action.location.lat = loc.lat;
    action.location.long = loc.lng;
  }

  return action;
}

const StatelessGoogleMapContainer = withScriptjs(withGoogleMap( () => { 

  return (<GoogleMap
      defaultZoom={5} 
      defaultCenter={ottawa}
  >

    { Object.keys(window.actions).map( (key, index) => {
        var action = window.actions[key]
        // var action = unplacedParkInOttawa(item);
        var markerSize = 28;
        return (
          <MarkerWithState
            action = { unplacedParkInOttawa(action) }
            isSelected = { false }
            position={  { lat: action.location.lat , lng: action.location.long } }
            icon = { 
              { 
                scaledSize: {
                  width: markerSize,
                  height: markerSize
                },
                url: action.state === "verified"
                  ? verifiedIcon
                  : unverifiedIcon
              }
            }
            key={ action.location.lat + action.location.long }
            onClick = { () => 
              {  
                //var url = "https://twitter.com/i/web/status/" + action.tweet.id
                //var bubble = window.open(url, '_blank');
                //bubble.focus(); 
              } 
            }   
          />
        )

    }) }
  </GoogleMap>)
  }
 )
);

export const MapComponent = props => {
    return (
        <div className="Map">
          <StatelessGoogleMapContainer
            googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyCQi4lfoBo4795EMns3Kyjk65P5dXB3m6w&v=3.exp&libraries=geometry,drawing,places"  
            loadingElement={<div style={{ height: `100%` }} />}
            containerElement={<div style={{ height: `650px` }} />}
            mapElement={<div style={{ height: `100%` }} />}
          />
        </div>
    );
  
};

const Map = MapComponent;

export default Map;
