import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

window.firebase.database()
   .ref('/ottVote_prod/submittedActions')
   .on('value', (snap) => {

     window.actions = snap.val()

     ReactDOM.render(<App/>, document.getElementById('root'));

     // If you want your app to work offline and load faster, you can change
     // unregister() to register() below. Note this comes with some pitfalls.
     // Learn more about service workers: http://bit.ly/CRA-PWA
     serviceWorker.unregister();

});

